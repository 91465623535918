<template>
	<div class="popup active">
		<div class="notice_popup">
			<div class="close">
				<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
			</div>
			<div class="tit"><span v-if="div == 'sco'">공급</span><span v-else>수요</span>기업 정보등록</div>
			<div class="member">기업정보를 등록하시겠습니까?</div>
			<div class="select-btn">
				<div class="btn" @click="$emit('close')">취소</div>
				<div class="btn" @click="$emit('close', true)">예</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props:['param'],
	data(){
		return{
			div : this.param.div
	
		}
	},
	
}
</script>